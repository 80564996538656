"use strict";

import * as THREE from "three";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import {
  CSS3DRenderer,
  CSS3DObject,
} from "three/addons/renderers/CSS3DRenderer.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as TWEEN from "@tweenjs/tween.js";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { VignetteShader } from "three/addons/shaders/VignetteShader.js";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
import { OutputPass } from "three/addons/postprocessing/OutputPass.js";

if (window.innerWidth / window.innerHeight < 1.5) {
  window.location.replace(`${window.location.href}site/`);
}

let sceneGl, sceneGl2, rendererGl, composer;
let sceneCss, rendererCss;
let camera, controls, pmremGenerator, raycaster, mouse, textureLoader;
let currX, currY;
let circleSprite, circleFadeIn, circleFadeOut;
let cameraTween, controlTween;
let fadeInStarted = false;
let fadeOutStarted = false;

const objects = [];

init();
initCssScene();
initGlScene().then(() => {
  document.querySelector(".loader-ring").classList.add("fadeout1");
  document.querySelector(".loader-wrapper").classList.add("fadein");
  document.querySelector(".label-wrapper").classList.add("fadein");
});

function init() {
  camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    10,
    1000000,
  );
  camera.position.set(8000, 9595, 15000);

  sceneGl = new THREE.Scene();
  sceneGl2 = new THREE.Scene();
  sceneGl.background = new THREE.Color("#212121");
  sceneCss = new THREE.Scene();

  rendererCss = new CSS3DRenderer();
  rendererCss.setSize(window.innerWidth, window.innerHeight);
  document.querySelector("#css").appendChild(rendererCss.domElement);

  rendererGl = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  rendererGl.setClearColor(0x00ff00, 0.0);
  rendererGl.setPixelRatio(window.devicePixelRatio);
  rendererGl.setSize(window.innerWidth, window.innerHeight);
  rendererGl.autoClear = false;
  rendererGl.toneMapping = THREE.ReinhardToneMapping;
  rendererGl.toneMappingExposure = 0.65;
  rendererGl.outputColorSpace = THREE.SRGBColorSpace;
  rendererGl.shadowMap.enabled = true;
  rendererGl.shadowMap.type = THREE.PCFSoftShadowMap;

  rendererGl.domElement.style.position = "absolute";
  rendererGl.domElement.style.zIndex = 1;
  rendererGl.domElement.style.top = 0;
  document.querySelector("#webgl").appendChild(rendererGl.domElement);

  controls = new OrbitControls(camera, document.querySelector("body"));
  controls.maxDistance = 450000;
  controls.target = new THREE.Vector3(-1315, 3290, -475);
  controls.enableDamping = true;
  controls.dampingFactor = 0.25;

  pmremGenerator = new THREE.PMREMGenerator(rendererGl);
  pmremGenerator.compileEquirectangularShader();

  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();

  textureLoader = new THREE.TextureLoader();

  composer = new EffectComposer(rendererGl);

  const renderPass = new RenderPass(sceneGl, camera);
  composer.addPass(renderPass);

  const effectVignette = new ShaderPass(VignetteShader);

  effectVignette.uniforms.offset.value = 0.2;
  effectVignette.uniforms.darkness.value = 1.3;
  composer.addPass(effectVignette);

  const outputPass = new OutputPass();
  composer.addPass(outputPass);
  composer.setSize(window.innerWidth, window.innerHeight);
}

function initCssScene() {
  const div = document.createElement("div");
  div.style.width = "720px";
  div.style.height = "480px";

  const iframe = document.createElement("iframe");
  iframe.style.width = "720px";
  iframe.style.height = "480px";
  iframe.style.border = "0px";
  iframe.style.resize = "both";
  iframe.style.overflow = "auto";
  iframe.style.opacity = 0.999;
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("allowfullscreen", true);
  iframe.src = `${window.location.href}site/`;
  div.appendChild(iframe);

  const arrow = document.querySelector(".arrow");
  arrow.addEventListener("pointerdown", () => {
    moveCamera({ x: 4500, y: 5595, z: 5500 }, 1000);
    moveControlTarget({ x: -1315, y: 3290, z: -475 }, 1000);
  });
  div.appendChild(arrow);

  const object = new CSS3DObject(div);
  object.position.set(-850, 3740, -5080);
  object.rotation.y = -0.6;
  sceneCss.add(object);

  const material = new THREE.MeshPhongMaterial({
    opacity: 0.2,
    color: 0x000000,
    blending: THREE.NoBlending,
    side: THREE.DoubleSide,
  });
  const geometry = new THREE.PlaneGeometry(720, 480);
  const mesh = new THREE.Mesh(geometry, material);
  mesh.position.copy(object.position);
  mesh.rotation.copy(object.rotation);
  mesh.castShadow = false;
  mesh.receiveShadow = true;
  sceneGl.add(mesh);
}

function initGlScene() {
  // ground
  const material = new THREE.MeshPhongMaterial({ depthWrite: false });
  const mesh = new THREE.Mesh(new THREE.CircleGeometry(15000), material);

  // lights
  const spotLight = new THREE.SpotLight(0xffffcc, 450000000);
  spotLight.penumbra = 1;
  spotLight.angle = 0.9;
  spotLight.distance = 12000;
  spotLight.position.set(-6600, 12800, 0);
  spotLight.target.position.set(-6600, 0, 0);
  spotLight.shadow.camera.near = 400;
  spotLight.shadow.camera.far = 8000000;
  sceneGl.add(spotLight);
  sceneGl.add(spotLight.target);

  const spotLight2 = new THREE.SpotLight(0xffffcc, 350000000);
  spotLight2.penumbra = 1;
  spotLight2.position.set(-0, 12800, 0);
  spotLight2.target.position.set(-0, 0, 0);
  spotLight2.shadow.camera.near = 400;
  spotLight2.shadow.camera.far = 8000000;
  sceneGl.add(spotLight2);
  sceneGl.add(spotLight2.target);

  const directionalLight = new THREE.DirectionalLight(undefined, 1.5);
  directionalLight.shadow.camera.top = 16000;
  directionalLight.shadow.camera.bottom = -16000;
  directionalLight.shadow.camera.left = -16000;
  directionalLight.shadow.camera.right = 16000;
  directionalLight.shadow.camera.near = 16000;
  directionalLight.shadow.camera.far = 60000;
  directionalLight.shadow.mapSize.width = 2048;
  directionalLight.shadow.mapSize.height = 2048;
  directionalLight.castShadow = true;
  directionalLight.position.set(8000, 40000, -8000);
  directionalLight.target.position.set(0, 0, 0);
  sceneGl.add(directionalLight);

  // scene.add( new THREE.CameraHelper( directionalLight.shadow.camera ) );

  return Promise.all([
    loadTexture("/images/Circle-PNG-HD-279x279-1670941709.png").then(
      (texture) => {
        const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
        circleSprite = new THREE.Sprite(spriteMaterial);
        circleSprite.scale.set(4000, 4000, 4000);
        circleSprite.position.set(-655, 3640, -4990);
        circleSprite.material.transparent = true;
        circleSprite.material.opacity = 0.1;
        sceneGl2.add(circleSprite);

        circleFadeIn = new TWEEN.Tween(circleSprite.material)
          .to({ opacity: 0.4 }, 1000)
          .onComplete(() => {
            circleFadeOut = new TWEEN.Tween(circleSprite.material)
              .to({ opacity: 0.1 }, 1000)
              .onComplete(() => circleFadeIn.start())
              .start();
          })
          .start();
      },
    ),
    loadTexture("/models/textures/material_baseColor.png").then((texture) => {
      material.map = texture;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      mesh.geometry.computeBoundingBox();

      const max = mesh.geometry.boundingBox.max;
      const min = mesh.geometry.boundingBox.min;
      const height = max.y - min.y;
      const width = max.x - min.x;

      texture.repeat.set(width / 3200, height / 3200);
      mesh.receiveShadow = true;
      texture.needsUpdate = true;
      mesh.rotation.x = -Math.PI / 2;

      mesh.position.set(-1500, 180, -1000);
      sceneGl.add(mesh);
    }),
    setupHdr(),
    setupGltf(),
  ]);
}

function loadTexture(url) {
  return new Promise((resolve) => {
    textureLoader.load(url, resolve);
  });
}

function setupHdr() {
  return new Promise((resolve) => {
    new RGBELoader()
      .setDataType(THREE.HalfFloatType)
      .load("/images/memorial.hdr", resolve);
  }).then((texture) => {
    const envMap = pmremGenerator.fromEquirectangular(texture).texture;

    sceneGl.environment = envMap;

    texture.dispose();
    pmremGenerator.dispose();
  });
}

function setupGltf() {
  return new Promise((resolve) => {
    new GLTFLoader().load("/models/scene.gltf", resolve);
  }).then((gltf) => {
    const gltfScene = gltf.scene;
    gltfScene.castShadow = true;

    gltfScene.traverse(function (child) {
      if (child.isMesh) {
        objects.push(child);
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
    gltfScene.scale.set(4000, 4000, 4000);
    sceneGl.add(gltfScene);
  });
}

function animate() {
  requestAnimationFrame(animate);

  controls.update();

  TWEEN.update();

  composer.render();
  rendererGl.clearDepth();
  rendererGl.render(sceneGl2, camera);
  rendererCss.render(sceneCss, camera);
}

function handleClick(event) {
  if (posDiff(event) || !intersects("Comp_Screen", event)) {
    return;
  }

  moveCamera({ x: -1043, y: 3740, z: -4798 }, 1000).onComplete(() => {
    if (window.innerWidth / window.innerHeight < 1.5) {
      document.querySelector("iframe").parentNode.style.width =
        `${window.innerWidth}px`;
      document.querySelector("iframe").style.width = `${window.innerWidth}px`;
    }
    sceneGl2.remove(circleSprite);
    window.removeEventListener("mouseup", handleClick);
  });
  moveControlTarget({ x: -850, y: 3740, z: -5080 }, 1000);
}

function checkPosChange(posA, posB) {
  return (
    comparePos(posA, posB, "x") ||
    comparePos(posA, posB, "y") ||
    comparePos(posA, posB, "z")
  );
}

function comparePos(posA, posB, arg) {
  return Math.round(posA[arg]) !== Math.round(posB[arg]);
}

function posDiff(event) {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const ratioX = (Math.abs(currX - event.clientX) * 100) / width;
  const ratioY = (Math.abs(currY - event.clientY) * 100) / height;

  return ratioX > 1 || ratioY > 1;
}

function intersects(tag, event) {
  mouse.x = (event.clientX / rendererGl.domElement.clientWidth) * 2 - 1;
  mouse.y = -(event.clientY / rendererGl.domElement.clientHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObjects(objects, false);

  if (intersects.length > 0 && intersects[0].object?.name?.includes(tag)) {
    return true;
  }
  return false;
}

function moveCamera(pos, time) {
  cameraTween?.stop();
  cameraTween = new TWEEN.Tween(camera.position);
  return cameraTween
    .onStart(() => (controls.enabled = false))
    .onComplete(() => (controls.enabled = true))
    .to(pos, time)
    .easing(TWEEN.Easing.Cubic.Out)
    .start();
}

function moveControlTarget(pos, time) {
  controlTween?.stop();
  controlTween = new TWEEN.Tween(controls.target);
  return controlTween
    .to(pos, time)
    .easing(TWEEN.Easing.Cubic.Out)
    .onUpdate((object) => {
      controls.target = new THREE.Vector3(object.x, object.y, object.z);
    })
    .start();
}

function handlePlayClick() {
  document.querySelector(".loader-play").classList.add("fadeout1");
  setTimeout(() => {
    animate();

    const curtain = document.querySelector(".curtain");
    curtain.classList.add("fadeout2");

    setTimeout(() => {
      curtain.parentNode.removeChild(curtain);
    }, 1500);
    moveCamera({ x: 4500, y: 5595, z: 5500 }, 2000);
  }, 200);
}

function handleMouseMove(event) {
  if (
    checkPosChange(camera.position, { x: -1043, y: 3740, z: -4798 }) ||
    checkPosChange(controls.target, { x: -850, y: 3740, z: -5080 })
  ) {
    window.addEventListener("mouseup", handleClick);
  }

  if (!intersects("Comp_Screen", event)) {
    document.querySelector("body").style.cursor = "default";

    fadeInStarted = false;
    if (fadeOutStarted) {
      return;
    }
    fadeOutStarted = true;
    circleFadeIn?.stop();
    circleFadeOut?.stop();
    new TWEEN.Tween(circleSprite?.material || {})
      .to({ opacity: 0.1 }, 1000)
      .easing(TWEEN.Easing.Cubic.Out)
      .onComplete(() => circleFadeIn?.start())
      .start();
    new TWEEN.Tween(circleSprite?.scale || {})
      .to({ x: 4000, y: 4000, z: 4000 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start();
    return;
  }
  document.querySelector("body").style.cursor = "pointer";
  fadeOutStarted = false;
  if (fadeInStarted) {
    return;
  }
  fadeInStarted = true;
  circleFadeIn?.stop();
  circleFadeOut?.stop();
  new TWEEN.Tween(circleSprite?.material || {})
    .to({ opacity: 0.6 }, 1000)
    .easing(TWEEN.Easing.Cubic.Out)
    .start();
  new TWEEN.Tween(circleSprite?.scale || {})
    .to({ x: 4250, y: 4250, z: 4250 }, 300)
    .easing(TWEEN.Easing.Cubic.Out)
    .start();
}

document
  .querySelector(".loader-play")
  .addEventListener("pointerdown", handlePlayClick);

window.addEventListener("mousemove", handleMouseMove);

window.addEventListener("resize", function () {
  const width = window.innerWidth;
  const height = window.innerHeight;
  rendererGl.setSize(width, height);
  rendererCss.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
});

window.addEventListener("mousedown", (event) => {
  currX = event.clientX;
  currY = event.clientY;
});

window.addEventListener("mouseup", handleClick);
